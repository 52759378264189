<template>
  <div class="content">
    <div class="box1">
      <div class="left">
        <div class="left-title">设备管理</div>
        <div class="left-text">
          能源管理系统的对象覆盖楼宇的各种大型能源设施，通过对能源设备的运行、异常、故障和事故状态实时监视和记录。通过技改和加强维护，指导维护保养工作，提高能源设备效率，实现能源设备闭环管理。
        </div>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  name: "",
  data() {
    return {};
  },

  methods: {},
};
</script>
 
<style scoped lang="scss">
.content {
  padding: 0 16%;
  min-height: calc(100vh - 317px);
  .box1 {
    width: 100%;
    height: 430px;
    display: flex;
    background-color: #fff;
    margin-top: 30px;
    padding: 30px;
    .left {
      width: 50%;
      padding: 5% 10%;
      box-sizing: border-box;
      .left-title {
        font-size: 28px;
        font-weight: 600;
        padding: 30px 0;
        color: #333333;
      }
      .left-text {
        font-size: 16px;
        font-weight: 400;
        color: #6b6b6b;
      }
    }
    .right {
      width: 50%;
      height: 100%;
      background: url("../../../../assets/image/buildingImg/蒙版组 145.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>